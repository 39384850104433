<template>
  <index-home />
</template>

<script>
import IndexHome from '@/components/Home/Hero';

export default {
  name: 'Home',
  components: {
    IndexHome
  }
}
</script>
